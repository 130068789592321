import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  const color = palette.augmentColor({ main: '#3995da' });

  return createStyles({
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      maxWidth: 800, 
      margin: "auto",
      cursor: 'pointer',
      borderRadius: spacing(0.5),
    },
    image: {
      borderRadius: spacing(0.5),
      '&:hover': {
        opacity: 0.2
      }
    },
    overlay: {
      position: 'relative',
      '&:after': {
          top: 0,
          left: 0,
          content: '" "',
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: '#0000006F',
          borderRadius: spacing(0.5),
      }
    },
    paper: {
      position: 'relative',
      display: 'flex',
      borderRadius: spacing(0.5),
      
    },
    dtc: {
      '&:hover:after': {
        backgroundColor: '#FFFFFF2F',
      },
      '&:after': {
        content: '" "',
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        transition: 'background 200ms ease-in',
        position: 'absolute',
        borderRadius: spacing(0.5),
      }
    },
    prayerGuide: {
      backgroundColor: '#3995da',
      color: '#FFF',
      justifyContent: 'center',
      padding: spacing(8),
      height: 450,
      transition: 'all 200ms ease-in',
      '&:hover, &:hover $prayerTextWrapper:after': {
        backgroundColor: color.light,
      }
    },
    prayerTextWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      border: '4px solid #F2F2F2',
      padding: spacing(0, 6),
      position: 'relative',
      '& h3, & h5': {
        zIndex: 1,
      },
      '&:after': {
        transition: 'all 200ms ease-in',
        content: '" "',
        display: 'flex',
        width: '108%',
        height: spacing(4),
        backgroundColor: '#3995da',
        position: 'absolute',
        left: -16,
      }
    },
    textWrapper: {
      position: "absolute",
      zIndex: 1,
      height: "100%",
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      borderRadius: spacing(0.5),
      transition: 'background 200ms ease-in',
      '&:hover': {
        backgroundColor: '#FFFFFF2F',
      },
      '&:after': {
        content: '" "',
        height: 'calc(100% - 48px)',
        width: 'calc(100% - 48px)',
        border: '12px solid #FFFFFF6F',
        top: 24,
        left: 24,
        zIndex: 1,
        pointerEvents: 'none',
        position: 'absolute'
      }
    },
    text: {
      color: palette.common.white,
    }
  });
});

export default useStyles;
