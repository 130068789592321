import { Container, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import useStyles from './Documents.styles';

const Documents = () => {
  const classes = useStyles();

  const { dtc, baptism } = useStaticQuery(graphql`
    {
      dtc: file(relativePath: { eq: "resources/dtc-cover.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      baptism: file(relativePath: { eq: "resources/baptism-booklet.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Container>
      <Typography align="center" variant="h2" style={{ marginBottom: 64 }}>
        Resources Available for Download
      </Typography>
      <Grid spacing={4} container style={{ marginBottom: 32 }}>
        <Grid xs={12} sm={12} md={6} item>
          <a href="/documents/dtc-2020.pdf" download="DTC 2020.pdf">
            <Paper className={clsx(classes.paper, classes.dtc)}>
              <GatsbyImage
                imgClassName={classes.image}
                image={dtc.childImageSharp.gatsbyImageData}
                alt="Become a Member"
              />
            </Paper>
          </a>
        </Grid>
        <Grid justify="space-between" item xs={12} sm={12} md={6}>
          <Grid style={{ marginBottom: 32 }} xs={12} item>
            <a
              style={{ textDecoration: 'none' }}
              href="/documents/pray-first-prayer-guide.pdf"
              download="Pray First Prayer Guide.pdf"
            >
              <Paper className={clsx(classes.paper, classes.prayerGuide)}>
                <div className={classes.prayerTextWrapper}>
                  <Typography variant="h3">Pray First</Typography>
                  <Typography variant="h5">Prayer Guide</Typography>
                </div>
              </Paper>
            </a>
          </Grid>
          <Grid xs={12} item>
            <a
              style={{ textDecoration: 'none' }}
              href="/documents/baptism-booklet.pdf"
              download="Baptism Booklet.pdf"
            >
              <Paper className={classes.paper}>
                <div className={classes.textWrapper}>
                  <Typography
                    style={{ fontWeight: 500 }}
                    align="center"
                    className={classes.text}
                    variant="h3"
                  >
                    Baptism Booklet
                  </Typography>
                </div>
                <GatsbyImage
                  className={classes.overlay}
                  imgClassName={classes.image}
                  image={baptism.childImageSharp.gatsbyImageData}
                  alt="Baptism Booklet"
                />
              </Paper>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Documents;
