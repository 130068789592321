import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

interface Image {
  name: string;
  data: IGatsbyImageData;
  originalSrc: string;
}

const Wallpapers = () => {
  const theme = useTheme();

  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/wallpapers/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 240, height: 430, layout: CONSTRAINED)
              original {
                src
              }
            }
            name
          }
        }
      }
    }
  `);

  const images: Image[] = allFile.edges
    .filter((e) => e.node.childImageSharp)
    .map((e) => ({
      name: e.node.name,
      data: e.node.childImageSharp.gatsbyImageData,
      originalSrc: e.node.childImageSharp.original.src,
    }));

  return (
    <Container>
      <Typography align="center" variant="h3" style={{ margin: '64px 0' }}>
        Mobile Wallpapers
      </Typography>
      <Grid justify="center" spacing={2} container>
        {images
          .sort((imgA, imgB) => {
            if (imgA.name.trim() > imgB.name.trim()) return 1;
            if (imgB.name.trim() > imgA.name.trim()) return -1;

            return 0;
          })
          .map((img, idx) => (
            <Grid
              alignItems="center"
              direction="column"
              style={{ display: 'flex', marginBottom: theme.spacing() }}
              key={idx}
              item
            >
              <GatsbyImage
                image={img.data}
                style={{
                  borderRadius: theme.spacing(0.5),
                  marginBottom: theme.spacing(),
                  boxShadow: theme.shadows[1],
                }}
                alt="mobile wallpaper"
              />
              <a
                style={{ textDecoration: 'none' }}
                href={img.originalSrc}
                download
              >
                <Button color="secondary" variant="contained">
                  Download
                </Button>
              </a>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default Wallpapers;
