import * as React from "react";
import Layout from "../layouts/Layout";

import { Documents, Wallpapers } from "../components/Resources";

const Resources = () => {
  return (
    <Layout title="Resources">
      <div style={{ marginTop: 80 }} />
      <Documents />
      <Wallpapers />
    </Layout>
  );
};

export default Resources;
